import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/uireact/uireact/node_modules/gatsby-theme-docz/src/base/Layout.js";
import * as packageJson from '../package.json';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "breakpoints"
    }}>{`Breakpoints`}</h1>
    <hr></hr>
    <sup>
  <a href="https://github.com/inavac182/ui-react/blob/main/packages/foundation/" target="_blank">
    v{packageJson.version}
  </a>
    </sup>
    <blockquote>
      <p parentName="blockquote">{`npm i @uireact/foundation`}</p>
    </blockquote>
    <h2 {...{
      "id": "definition-️"
    }}>{`Definition ✍️`}</h2>
    <p>{`We currently support these breakpoints:`}</p>
    <pre><code parentName="pre" {...{}}>{`enum Breakpoints {
  SMALL,
    ▶︎ Used to render on mobile devices
  MEDIUM,
    ▶︎ Used to render on tablet devices or mobiles with big screens or mobile landscape
  LARGE,
    ▶︎ Used to render on big tablets, tablets on landscape or desktop
  XLARGE,
    ▶︎ Used to render on high resolutions desktop screens
}
`}</code></pre>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://github.com/inavac182/uireact/blob/main/packages/foundation/src/types/breakpoints-enum.ts"
      }}>{`Breakpoints enum`}</a>{` is exported as part of the package @ui-react/foundation
this enum has the different breakpoints we support.`}</p>
    <h2 {...{
      "id": "breakpoints-sizes"
    }}>{`Breakpoints sizes`}</h2>
    <p>{`Based on data around internet of the most common resolutions for each device we have decided to set up the supported breakpoints sizes
in the following distribution:`}</p>
    <pre><code parentName="pre" {...{}}>{`const BreakpointsSizes = {
  s: {
    max: 579,
      ▶︎ No min value as anything from 0 up to 579px considered SMALL
  },
  m: {
    min: 580,
    max: 991,
  },
  l: {
    min: 992,
    max: 1440,
  },
  xl: {
    min: 1440,
      ▶︎ No max value as anything above 1440 is considered XLARGE
  },
};
`}</code></pre>
    <p><strong parentName="p">{`NOTE:`}</strong>{` @uireact doesn't distinguish between real mobile device and desktop small breakpoint, they are both categorized as SMALL.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      